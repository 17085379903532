import React, {useContext, useEffect} from 'react';
import {ChatProvider} from 'hook/ChatContext';
import {LanguageProvider} from 'hook/LanguageContext';
import {GlobalProvider, GlobalContext} from 'hook/GlobalContext';
import Footer from 'components/footer';
import Header from 'components/header';
import * as styles from './contact-us.module.scss';

const DEFAULT_LIVONGO_NUMBER = '1-800-945-4355';
const PL_NUMBER = 'PL015934.A';

const programLinks = [
    {
        link: '/diabetes',
        name: 'Diabetes',
        newTab: true,
        convertEnvironment: true,
    },
    {
        link: '/healthy-living',
        name: 'Diabetes Prevention',
        newTab: true,
        convertEnvironment: true,
    },
    {
        link: '/hypertension',
        name: 'High Blood Pressure',
        newTab: true,
        convertEnvironment: true,
    },
    {
        link: '/healthy-living',
        name: 'Weight Management',
        newTab: true,
        convertEnvironment: true,
    },
    {
        link: 'https://www.myStrength.com',
        name: 'Mental Health',
        newTab: true,
        convertEnvironment: false,
    },
];

const aboutLinks = [
    {
        link: 'https://teladochealth.com/about/',
        name: 'Company',
        newTab: true,
        convertEnvironment: false,
    },
    {
        link: 'https://teladochealth.com/',
        name: 'For organizations',
        newTab: true,
        convertEnvironment: false,
    },
    {
        link: 'https://providers.teladochealth.com/',
        name: 'For care providers',
        newTab: true,
        convertEnvironment: false,
    },
    {
        link: 'https://teladochealth.com/en/careers/',
        name: 'Careers',
        newTab: true,
        convertEnvironment: false,
    },
    {
        link: 'https://ir.teladochealth.com/investors/default.aspx',
        name: 'Investors',
        newTab: true,
        convertEnvironment: false,
    },
];

const supportLinks = [
    {
        link: '/contact-us',
        name: 'Contact Us',
        newTab: true,
        convertEnvironment: false,
    },
];

const floatingLinks = [
    {
        name: 'Web and Mobile Privacy Policy',
        link: 'https://teladochealth.com/privacy-policy/',
        newTab: true,
        convertEnvironment: false,
    },
    {
        name: 'Legal Disclaimer',
        link: 'https://teladochealth.com/legal-disclaimer/',
        newTab: true,
        convertEnvironment: false,
    },
    {
        name: 'Notice of Non-Discrimination and Language Assistance',
        link: 'https://teladochealth.com/notice-of-non-discrimination/',
        newTab: true,
        convertEnvironment: false,
    },
];
const disclaimer = {
    raw:
        '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Livongo is part of Teladoc Health, Inc., a mission-driven organization successfully transforming how people access and experience healthcare, with a focus on high quality, lower costs and improved outcomes around the world.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
};

const ContactUs = props => {
    const {setContactNumber} = useContext(GlobalContext);

    useEffect(() => {
        setContactNumber(DEFAULT_LIVONGO_NUMBER);
    }, []);

    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);

    return (
        <>
            <div className={styles.container}>
                <Header />

                <div className={styles.main}>
                    <span className={styles.title}>Contact Us</span>

                    <div className={styles.items}>
                        <h2>Member Support 24 hours a day</h2>
                        <a
                            className={styles.contactNumber}
                            href={`${contactPhoneURL}`}
                        >
                            {contactNumber}
                        </a>
                        <p>membersupport@livongo.com</p>
                    </div>

                    <div className={styles.items}>
                        <h2>Careers</h2>
                        <p>
                            Visit the Teladoc Health{' '}
                            <a href="https://teladochealth.com/careers/">
                                careers page
                            </a>
                        </p>
                    </div>

                    <div className={styles.items}>
                        <h2>Sales/Press/Media</h2>
                        <p>
                            Visit the Teladoc Health{' '}
                            <a href="https://teladochealth.com/contact/">
                                contact page
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            <Footer
                aboutLinks={aboutLinks}
                disclaimer={disclaimer}
                floatingLinks={floatingLinks}
                plNumber={PL_NUMBER}
                programLinks={programLinks}
                supportLinks={supportLinks}
            />
        </>
    );
};

const ContactUsContextWrapper = props => {
    return (
        <GlobalProvider>
            <LanguageProvider>
                <ChatProvider>
                    <ContactUs {...props} />
                </ChatProvider>
            </LanguageProvider>
        </GlobalProvider>
    );
};

export default ContactUsContextWrapper;
